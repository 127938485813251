import moment, { Moment } from "moment";
import { LabeledTextField } from "../components/LabeledTextField";
import { L } from "../lib/abpUtility";
import { CheckBoxBase } from "../scenes/BaseComponents/CheckBoxBase";
import { CheckBoxOptions } from "../scenes/BaseComponents/CheckBoxOptions";
import { ChoiceGroupBase } from "../scenes/BaseComponents/ChoiceGroupBase";
import { ContentViewModelProperty } from "../scenes/BaseComponents/contentViewBase";
import { Controls } from "../scenes/BaseComponents/controls";
import { CountriesComboBox } from "../scenes/BaseComponents/CountriesComboBox";
import { CountryFluentListBase } from "../scenes/BaseComponents/countryFluentListBase";
import { DatePickerBase } from "../scenes/BaseComponents/datePickerBase";
import { DropdownBase } from "../scenes/BaseComponents/dropdownBase";
import { MultiDropdownBase } from "../scenes/BaseComponents/multiDropdownBase";
import { SportFluentListBase } from "../scenes/BaseComponents/sportFluentListBase";
import { TableInputsBase } from "../scenes/BaseComponents/TableInputsBase";
import { TimePicker } from "../scenes/BaseComponents/timePicker";
import { CalculationSectionType } from "../services/dto/calculationSectionTypeEnums";
import { InsuranceCompanyDto } from "../services/insuranceCompany/insuranceCompanyDto";
import { InsurancePolicyStatus } from "../services/insurancePolicy/insurancePolicyStatusEnums";
import { OrderStatus } from "../services/order/enums/orderStatusEnums";
import { KeysToIdMapper } from "../services/product/productDto";
import productService from "../services/product/productService";
import { defaultClient } from "../stores/clientStore";
import { defaultKeysToIdMapper } from "../stores/productStore";
import { fluentTableClassNames } from "../styles/fluentTableStyles";
import { getLocaleName } from "./languageUtils";
import { filterBySome, isJsonString, peselDecode } from "./utils";
import { parseEnumToStringArray } from "./modelUtils";
import { InsuranceCompanyNewOffer } from "../services/calculation/dto/insuranceCompanyNewOfferEnum";

export function mapAttributeKeyToMappingId(allUserFields: any, productAttributeMappings: any, attributeKey: string, product?: any): string {
    let mappedIdToReturn: string = '';
    let productName: string = product && product.Name ? product.Name : '';

    let keyToId = mapAttributeKeyToId(productAttributeMappings, attributeKey);
    if(keyToId.length === 0) {
        keyToId = attributeKey;
    }

    allUserFields.some((UserField: any) => {
        if(!!productName && UserField.productName !== productName) {
            return false;
        } else {
            if(UserField.id === keyToId) {
                mappedIdToReturn = UserField.mappingId;
                return true;
            }
            return false;
        }
    });

    return mappedIdToReturn;
}

export function mapAttributeKeyToId(productAttributeMappings: any, attributeKey: string, isFullKey?: boolean): string {
    let idToReturn = '';

    productAttributeMappings.some((productAttributeMapping: any) => {
        const UserFields: any[] = !!productAttributeMapping.UserFields ? productAttributeMapping.UserFields : 
                                    (!!productAttributeMapping.ProductAttribute && productAttributeMapping.ProductAttribute.UserFields ? 
                                        productAttributeMapping.ProductAttribute.UserFields : []);

        UserFields.some((UserField: any) => {
            if(UserField.Key === 'key') {
                if(isFullKey === true && UserField.Value === attributeKey) {
                    idToReturn = productAttributeMapping.Id;
                } else {
                    let splitedValue = UserField.Value.split('.');
    
                    if(attributeKey === UserField.Value || splitedValue[splitedValue.length - 1] === attributeKey) {
                        idToReturn = productAttributeMapping.Id;
                    }
                }
                return true;
            }
            return false;
        });

        if(idToReturn.length > 0) {
            return true;
        }
        return false;
    });

    return idToReturn;
}

export function getUserFieldsFromProductAttributeMapping(productAttributeMappings: any, attrId: string): any[] {
    let userFieldsToReturn: any = [];

    productAttributeMappings.some((productAttributeMapping: any) => {
        if(productAttributeMapping.Id === attrId) {
            if(productAttributeMapping.ProductAttribute && productAttributeMapping.ProductAttribute.UserFields) {
                userFieldsToReturn = productAttributeMapping.ProductAttribute.UserFields;
            }
            return true;
        }
        return false;
    });

    return userFieldsToReturn;
}

export function parseTableInputData(attribute: any, data: any, justMapKeys: boolean = false, mapperName: string): string {
    if(!data.keyPairValue || !isJsonString(data.keyPairValue)) {
        return '';
    }

    let parsedValue: any = JSON.parse(data.keyPairValue);
    let translatedString: string = '\n\r';

    for(let key in parsedValue) {
        if(parsedValue.hasOwnProperty(key)) {
            for(let attrId in parsedValue[key]) {
                if(parsedValue[key].hasOwnProperty(attrId)) {
                    let attributeData = getAttributeNameAndValue(data.product, data.productAttributes, attrId, parsedValue[key][attrId], data.gnLanguage, mapperName, data.additionalData);
                    if(justMapKeys) {
                        if(attributeData.map && !!attributeData.map) {
                            parsedValue[key][attributeData.map] = parsedValue[key][attrId];

                            if(attributeData.value && attributeData.value.length > 0) {
                                parsedValue[key][attributeData.map] = attributeData.value;
                            }

                            delete parsedValue[key][attrId];
                        } else {
                            parsedValue[key][attributeData.name] = parsedValue[key][attrId];
                            
                            if(attributeData.value && attributeData.value.length > 0) {
                                parsedValue[key][attributeData.name] = attributeData.value;
                            }
    
                            delete parsedValue[key][attrId];
                        }
                    } else {
                        if(attributeData.translatedValue && attributeData.translatedValue.length > 0) {
                            translatedString += `${attributeData.name}: ${attributeData.translatedValue} | `;
                            parsedValue[key][attributeData.name] = attributeData.translatedValue;
                        } else {
                            // TODO find out if string value is date, if so - display formated date
                            // console.info(moment(parsedValue[key][attrId]), moment(parsedValue[key][attrId], "MM/DD/YYYY"));
                            // console.info(new Date(parsedValue[key][attrId]));
                            // if(moment(parsedValue[key][attrId], "MM/DD/YYYY", true).isValid()) {
                            //     translatedString += `${attributeData.name}: ${dateFormat(parsedValue[key][attrId], "DD.MM.YYYY")} | `;
                            //     parsedValue[key][attributeData.name] = dateFormat(parsedValue[key][attrId], "DD.MM.YYYY");
                            // } else {
                                translatedString += `${attributeData.name}: ${parsedValue[key][attrId]} | `;
                                parsedValue[key][attributeData.name] = parsedValue[key][attrId];
                            // }
                        }

                        delete parsedValue[key][attrId];
                    }
                }
            }
            translatedString = translatedString.substring(0, translatedString.length - 3);
            translatedString += '\n\r';
        }
    }

    if(justMapKeys) {
        return JSON.stringify(parsedValue);
    } else {
        return translatedString;
    }
}

export function getAttributeNameAndValue(product: any, productAttributes: any, key: string, keyPairValue: string, gnLanguage: string, mapperName: string, additionalData?: any): any {
    let returnAttrName = "";
    let returnAttrValue = "";
    let returnAttrTranslatedValue = "";
    let returnAttrMap = "";
    let returnAttrValueLocales: any[] = [];

    if(product.ProductAttributeMappings && product.ProductAttributeMappings.length > 0) {
        product.ProductAttributeMappings!.some((attr: any, i: number) => {
            if(attr.Id === key || (additionalData && additionalData.parentKey && attr.Id === additionalData.parentKey)) {
                if(productAttributes) {
                    productAttributes.some((attribute: any) => {
                        if(attr.ProductAttributeId === attribute.Id) {
                            let controlType: string = attr.AttributeControlTypeId;

                            if(attribute.Locales) {
                                returnAttrName = getLocaleName(attribute.Locales, gnLanguage);
                            } else {
                                returnAttrName = attribute.Name;
                            }

                            if(attribute.UserFields && attribute.UserFields.length > 0) {
                                let keysFound = 0;
                                attribute.UserFields.some((UserField: any) => {
                                    if(UserField.Key === 'customControlType' && !!UserField.Value) {
                                        controlType = UserField.Value;
                                    }

                                    if(UserField.Key === 'dataSource' && additionalData && typeof additionalData.dataSourceStore !== 'undefined') {
                                        returnAttrValue = getValueFromCustomDataSource(additionalData.dataSourceStore, keyPairValue);
                                        returnAttrTranslatedValue = L(returnAttrValue);
                                        keysFound++;
                                    }

                                    if(UserField.Key === mapperName) {
                                        returnAttrMap = UserField.Value;
                                        keysFound++;
                                    }

                                    if(keysFound === 2 || (keysFound === 1 && (!additionalData || typeof additionalData.dataSourceStore === 'undefined'))) {
                                        return true;
                                    }
                                    return false;
                                });
                            }

                            if(controlType === "ColorSquares" || controlType === "Table") {
                                returnAttrValue = parseTableInputData(attribute, {product, productAttributes, key, keyPairValue, gnLanguage, additionalData}, true, mapperName);
                                returnAttrTranslatedValue = parseTableInputData(attribute, {product, productAttributes, key, keyPairValue, gnLanguage, additionalData}, undefined, mapperName);
                            }

                            return true;
                        }
                        return false;
                    });
                } else {
                    returnAttrName = attr.Name;
                }
    
                if(attr.ProductAttributeValues.length > 0) {
                    attr.ProductAttributeValues.some((attrValue: any) => {
                        if(attrValue.Id === keyPairValue || (additionalData && additionalData.parentKey && attrValue.Id === key)) {
                            if(returnAttrTranslatedValue.length === 0) {
                                returnAttrTranslatedValue = attrValue.Locales && attrValue.Locales.length > 0 ? getLocaleName(attrValue.Locales, gnLanguage) : attrValue.Name;
                                returnAttrValueLocales = attrValue.Locales;
                            }
                            if(returnAttrValue.length === 0) {
                                returnAttrValue = attrValue.Name;
                            }
                            return true;
                        }
                        return false;
                    });
                }

                return true;
            }
    
            return false;
        });
    }

    if(key === 'vehicleBrand' || key === 'vehicleModel') {
        returnAttrName = L(key);
    }

    if(additionalData && additionalData['returnLocales']) {
        return {"name": returnAttrName, "value": returnAttrValue, "translatedValue": returnAttrTranslatedValue, "map": returnAttrMap, "locales": returnAttrValueLocales};
    } else {
        return {"name": returnAttrName, "value": returnAttrValue, "translatedValue": returnAttrTranslatedValue, "map": returnAttrMap};
    }
}

function getValueFromCustomDataSource(dataSource: any, value: string): string {
    let valueToReturn = "";

    if(dataSource && dataSource.dataSet && dataSource.dataSet.totalCount > 0) {
        dataSource.dataSet.items.some((data: any) => {
            if(data.Id === value && data.Name && data.Name.length > 0) {
                if(!!data.Locales && data.Locales.length > 0) {
                    data.Locales.some((locale: any) => {
                        if(locale.LanguageId === "6156fbbb3b62a9f300b37fb6" && locale.LocaleKey === "Name") {
                            valueToReturn = locale.LocaleValue;
                            return true;
                        }
                        return false;
                    });
                } else {
                    valueToReturn = data.Name;
                }
                return true;
            }
            return false;
        });
    }

    return valueToReturn;
}

export async function getProductKeysToIdMapper(): Promise<KeysToIdMapper> {
    return await productService.getProductKeysToIdMapper();
}

export function mapAttributeNameToId(name: string, productId: string, keysToIdMapper: KeysToIdMapper): string {     
    if (keysToIdMapper !== defaultKeysToIdMapper) {
        let findedId = keysToIdMapper.attributeNameToIdList
                                                .filter(x => x.key === name && x.productId === productId)
                                                .map(x => x.id);
        if(findedId[0] !== undefined) {
            return findedId[0];
        }
    }
    return "";
}

export function buildRequestBody(requestType: string, that: any, calculationType: string, mapperName: string, keysToIdMapper: KeysToIdMapper = defaultKeysToIdMapper): any {
    let requestBody: any = {};
    const doNotIncludeKeys: string[] = ['vehicleTypeId', 'vehicleBrandId', 'vehicleModelId', 'vehicleConfigurationId'];

    let attrNames: string[] = [];
    let attrValues: string[] = [];
    let attrTranslatedValues: string[] = [];
    let attrMap: string[] = [];
    let attrKey: string[] = [];
    let attributeDescription: string = "";

    for(let key in that.inputsTypeValuePairs) {
        if(that.inputsTypeValuePairs.hasOwnProperty(key) && !doNotIncludeKeys.includes(key)) {
            attrKey.push(key);

            let tempAttrValue = "";
            let tempAttrTranslatedValue = "";

            if(typeof that.inputsTypeValuePairs[key] === 'string' && !isJsonString(that.inputsTypeValuePairs[key])) {
                tempAttrValue = that.inputsTypeValuePairs[key];
            } if(typeof that.inputsTypeValuePairs[key] === 'number') {
                tempAttrValue = that.inputsTypeValuePairs[key].toString();
            } else if(typeof that.inputsTypeValuePairs[key] === 'object' || (typeof that.inputsTypeValuePairs[key] === 'string' && isJsonString(that.inputsTypeValuePairs[key]))) {
                let objectToCheck: any = that.inputsTypeValuePairs[key];

                if(typeof that.inputsTypeValuePairs[key] === 'string' && isJsonString(that.inputsTypeValuePairs[key])) {
                    objectToCheck = JSON.parse(that.inputsTypeValuePairs[key]);
                }

                if(typeof objectToCheck === 'object') {
                    let multipleValues: string[] = [];

                    for(let jsonKey in objectToCheck) {
                        if(objectToCheck.hasOwnProperty(jsonKey) && objectToCheck[jsonKey]) {
                            multipleValues.push(getAttributeNameAndValue(that.product, that.props.productAttributeStore?.dataSet.items, jsonKey, objectToCheck[jsonKey], that.gnLanguage, mapperName, {"parentKey": key}).value);
                        }
                    }
                    
                    let hasAnyValue: boolean = false;
                    multipleValues.some((value: string) => {
                        if(!!value) {
                            hasAnyValue = true;
                            return true;
                        }
                        return false;
                    });
    
                    if(hasAnyValue) {
                        tempAttrValue = multipleValues.join(', ');
                    } else {
                        tempAttrValue = that.inputsTypeValuePairs[key];
                    }
                } else if(typeof objectToCheck === 'number') {
                    tempAttrValue = that.inputsTypeValuePairs[key].toString();
                } else {
                    tempAttrValue = that.inputsTypeValuePairs[key];
                }
            }

            let attrData = getAttributeNameAndValue(that.product, that.props.productAttributeStore?.dataSet.items, key, that.inputsTypeValuePairs[key], that.gnLanguage, mapperName, {"dataSourceStore": that.props.countryStore});

            if(attrData.value.length > 0) {
                tempAttrValue = attrData.value;
                tempAttrTranslatedValue = attrData.translatedValue;
            } else {
                tempAttrTranslatedValue = tempAttrValue;
            }

            attrMap.push(attrData.map);
            attrNames.push(attrData.name);
            attrValues.push(typeof tempAttrValue === 'object' ? JSON.stringify(tempAttrValue) : tempAttrValue);
            attrTranslatedValues.push(typeof tempAttrTranslatedValue === 'object' ? JSON.stringify(tempAttrTranslatedValue) : tempAttrTranslatedValue);
        }
    }

    const translatedJson: any = {
        "source": {},
        "translated": {},
        "translatedValue": {},
        "translatedKeyValue": {},
    };
    const dataObjects: any[] = [];
    const apkDataObjects: any[] = [];

    attrKey.forEach((key: string, index: number) => {
        translatedJson.source[key] = that.inputsTypeValuePairs[key];
        translatedJson.translated[attrMap[index].length > 0 ? attrMap[index] : key] = attrValues[index];
        translatedJson.translatedValue[attrMap[index].length > 0 ? attrMap[index] : key] = attrTranslatedValues[index];
        translatedJson.translatedKeyValue[attrNames[index].length > 0 ? attrNames[index] : key] = attrTranslatedValues[index];

        attributeDescription += `${attrNames[index]}: ${attrTranslatedValues[index]}<br />`;

        if(requestType === 'createOrder' || requestType === 'updateOrder' || requestType === 'getCalculations' || requestType === 'getCalculation') {
            dataObjects.push(createInputDataObjectForApiRequest(key, that.inputsIdUserFieldsPairs, attrValues[index], undefined, that.inputsTypeValuePairs[key], requestType === 'getCalculations' || requestType === 'getCalculation' ? true : false));
            apkDataObjects.push(createInputDataObjectForApiRequest(key, that.inputsIdUserFieldsPairs, attrValues[index], ['is_for_apk'], that.inputsTypeValuePairs[key], requestType === 'getCalculations' || requestType === 'getCalculation' ? true : false));
        } else {
            dataObjects.push(createInputDataObjectForApiRequest(key, that.inputsIdUserFieldsPairs, attrValues[index]));
        }
    });

    const calculationData: any = {
        "calculationType": calculationType,
        "insurerName": that.selectedCalculation ? that.selectedCalculation.insurerName : '',
        "insurerId": that.selectedCalculation ? that.selectedCalculation.gnInsurerId : '',
        "offerNumber": that.selectedCalculation && !!that.selectedCalculation.offerNumber ? that.selectedCalculation.offerNumber : '',
        "calculationId": that.selectedCalculation && isCalculationIdSet(that.selectedCalculation.calculationId) ? 
                        (typeof that.selectedCalculation.calculationId === 'number' ? that.selectedCalculation.calculationId.toString() : that.selectedCalculation.calculationId) : '',
    }

    const dataObjFiltered: any[] = [...dataObjects.filter(x => typeof x !== 'undefined' && x.core_path && x.core_type)];
    const apkDataObjFiltered: any[] = [...apkDataObjects.filter(x => typeof x !== 'undefined' && x.is_for_apk)];
    const reducedDataObjFiltered: any[] = getReducedDataObjects(dataObjFiltered, that);

    const shortInsurerTypeValuePairs: any = that.clientTypeValuePairs[CalculationSectionType.Insurer];
    let insurerPrefix: string = '';
    switch(that.mapKeyToId('mapProductIdToType', that.product.Id)) {
        case "Vehicle":
            insurerPrefix = "Auto";
            break;
        case "Home":
            insurerPrefix = "Dom";
            break;
        case "Travel":
            insurerPrefix = "Podr";
            break;
        case "Children":
            insurerPrefix = "Nnwd";
            break;
        case "CancelTravel":
            insurerPrefix = "Rzpd";
            break;
    }
    insurerPrefix = `${insurerPrefix}.Insurer`;

    const thatCustomer: any = that.customer ? that.customer : (that.client ? that.client : defaultClient);

    switch(requestType) {
        case 'getCalculations':
            requestBody = {
                "clientId": that.selectedClient,
                "calculationType": !!that.isFastCalculation && that.isFastCalculation === true ? "fast_calculation" : "standard",
                "type": calculationType,
                "data": getReducedDataObjects(dataObjFiltered, that, true),
                "additionalData": JSON.stringify(apkDataObjFiltered),
                "gnProductId": that.product.Id,
                "apkData": that.preSettedApkData ? that.preSettedApkData : [],
            };
            
            if(that.props.policyCalculation?.payloadType === "calculation") {
                requestBody["calculationId"] = that.props.policyCalculation?.payloadId;
            } else if(that.getCalculationsRawResponse && !!that.getCalculationsRawResponse.calculationId) {
                requestBody["calculationId"] = that.getCalculationsRawResponse.calculationId;
            }

            break;
        case 'getCalculation':
            requestBody = {
                "clientId": that.selectedClient,
                "calculationType": !!that.isFastCalculation && that.isFastCalculation === true ? "fast_calculation" : "standard",
                "type": calculationType,
                "data": reducedDataObjFiltered,
            };

            break;
        case 'policyManualCreate':
            requestBody = {
                "clientId": that.selectedClient,
                "segment": calculationType,
                "policyStatus": InsurancePolicyStatus.Accepted,
                "isApkSinged": true,
                "data": reducedDataObjFiltered,
                "apkData": that.preSettedApkData ? that.preSettedApkData : [],
            };

            break;
        case 'policyApplication':
            if(that.lastGetCalculationPayload && that.lastGetCalculationPayload.payload && that.lastGetCalculationPayload.payload.data && !!that.lastGetCalculationPayload.insurerName && that.lastGetCalculationPayload.insurerName === calculationData.insurerName) {
                reducedDataObjFiltered.forEach((dataObj: any) => {
                    if(dataObj.value === null || dataObj.value.length === 0) {
                        that.lastGetCalculationPayload.payload.data.some((savedDataObj: any) => {
                            if(savedDataObj.core_path === dataObj.core_path) {
                                if(!!savedDataObj.value) {
                                    dataObj.value = savedDataObj.value;
                                }
    
                                return true;
                            }
                            return false;
                        });
                    }
                });
            } else {
                reducedDataObjFiltered.forEach((dataObj: any) => {
                    if(dataObj.value === null || dataObj.value.length === 0) {
                        that.lastGetCalculationsPayload.data.some((savedDataObj: any) => {
                            if(savedDataObj.core_path === dataObj.core_path) {
                                if(!!savedDataObj.value) {
                                    dataObj.value = savedDataObj.value;
                                }
    
                                return true;
                            }
                            return false;
                        });
                    }
                });
            }

            requestBody = {
                "apkData": that.preSettedApkData ? that.preSettedApkData : [],
                "currentLanguage": {
                    id: that.gnLanguage.Id,
                    name: that.gnLanguage.Name,
                    seoCode: that.gnLanguage.UniqueSeoCode,
                    culture: that.gnLanguage.LanguageCulture,
                },
                "type": calculationType,
                "gnOrderId": that._returnedCreatedOrder.id,
                "insurerName": calculationData.insurerName,
                "offerNumber": calculationData.offerNumber,
                "calculationId": calculationData.calculationId,
                "customerId": that.selectedCustomer,
                "clientId": that.selectedClient,
                "customerEmail": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Email`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Email`] : thatCustomer.user.email,
                "customerName": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.FirstName`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.FirstName`] : thatCustomer.user.firstName,
                "customerSurname": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Surname`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Surname`] : thatCustomer.user.lastName,
                "orderId": that._returnedCreatedOrder.id,
                "orderGuid": that._returnedCreatedOrder.orderGuid,
                "orderNumber": that._returnedCreatedOrder.orderNumber,
                "orderDate": that._returnedCreatedOrder.createdOnUtc,
                "data": reducedDataObjFiltered,
                "agreementsList": [],
            };

            for(let code in that.summaryAgreements) {
                if(that.summaryAgreements.hasOwnProperty(code)) {
                    requestBody["agreementsList"].push(that.summaryAgreements[code]);
                }
            };

            if(calculationData.insurerName === "TUZ") {
                requestBody["insurerCalculationId"] = calculationData.calculationId;
            }
            // if(calculationData && !!calculationData.calculationId) {
            //     requestBody["calculationId"] = calculationData.calculationId;
            // }

            // if(that.props.policyCalculation?.payloadType === "calculation") {
            //     requestBody["calculationId"] = that.props.policyCalculation?.payloadId;
            // }
            if(that.getCalculationsRawResponse && !!that.getCalculationsRawResponse.calculationId) {
                requestBody["calculationId"] = that.getCalculationsRawResponse.calculationId;
            }

            break;
        case 'policyFinalization':
            requestBody = {
                "abpPolicyId": that._returnedAbpPolicyId,
            }
            break;
        case 'policyFinalizationWithAcceptanceUw':
            requestBody = {
                "acceptanceCodeUW": that.uwAcceptanceCode,
                "abpPolicyId": that._returnedAbpPolicyId,
            }
            break;
        case 'createOrder':
        case 'updateOrder':
            let attributes: any[] = [];

            for (let key in that.inputsTypeValuePairs) {
                if (that.inputsTypeValuePairs.hasOwnProperty(key)) {
                    if(typeof that.inputsTypeValuePairs[key] === 'string') {
                        attributes.push({ "Key": key, "Value": that.inputsTypeValuePairs[key] });
                    } else {
                        for (let valueKey in that.inputsTypeValuePairs[key]) {
                            if(that.inputsTypeValuePairs[key].hasOwnProperty(valueKey) && typeof that.inputsTypeValuePairs[key][valueKey] !== 'undefined') {
                                let value = that.inputsTypeValuePairs[key][valueKey];
                                if(typeof value === 'number' || typeof value === 'boolean') {
                                    value = value.toString();
                                } else if(typeof value === 'object' && !!JSON.stringify(value)) {
                                    value = JSON.stringify(value);
                                } else if(typeof value !== 'string') {
                                    value = String(value);
                                }

                                attributes.push({ "Key": key, "Value": value });
                            }
                        }
                    }
                }
            }

            translatedJson.translated = {...translatedJson.translated, ...calculationData};
            translatedJson.translatedValue = {...translatedJson.translatedValue, ...calculationData};

            translatedJson.translatedKeyValue[`${L('Calculation type')}`] = calculationData.calculationType;
            translatedJson.translatedKeyValue[`${L('Insurer')}`] = calculationData.insurerName;
            translatedJson.translatedKeyValue[`${L('Insurer ID')}`] = calculationData.insurerId;

            if(calculationData.insurerName === "TUZ") {
                translatedJson.translatedKeyValue[`${L('Calculation ID')}`] = calculationData.calculationId;
            }
            
            const mappedApiCall: string = mapAttributeNameToId("apiCall", that.product?.Id, keysToIdMapper);
            const mappedDataObjFiltered: string = mapAttributeNameToId("dataObjFiltered", that.product?.Id, keysToIdMapper);
            const mappedInsurer: string = mapAttributeNameToId("insurer", that.product?.Id, keysToIdMapper);
            const mappedInsurerId: string = mapAttributeNameToId("insurerId", that.product?.Id, keysToIdMapper);
            attributes.push({ "Key": !!mappedApiCall ? mappedApiCall : "apiCall", "Value": JSON.stringify(translatedJson) });
            attributes.push({ "Key": !!mappedDataObjFiltered ? mappedDataObjFiltered : "dataObjFiltered", "Value": JSON.stringify(reducedDataObjFiltered) });
            attributes.push({ "Key": !!mappedInsurer ? mappedInsurer : "insurer", "Value": calculationData.insurerName });
            attributes.push({ "Key": !!mappedInsurerId ? mappedInsurerId : "insurerId", "Value": calculationData.insurerId });

            if(calculationData.insurerName === "TUZ") {
                attributes.push({ "Key": "calculationId", "Value": calculationData.calculationId });
            }
            requestBody["SeName"] = null;
            requestBody["CustomerId"] = that.selectedCustomer;
            requestBody["OwnerId"] = that.selectedCustomer;
            requestBody["OrderStatusId"] = OrderStatus.Saved;
            // requestBody["ShippingStatusId"] = ShippingStatus.ShippingNotRequired;
            requestBody["ShippingStatusId"] = "ShippingNotRequired";
            // requestBody["PaymentStatusId"] = PaymentStatus.Pending;
            requestBody["PaymentStatusId"] = "Pending";
            requestBody["PaymentMethodSystemName"] = null;
            requestBody["PaymentOptionAttribute"] = null;
            requestBody["CustomerCurrencyCode"] = that.selectedCalculation.currency;
            requestBody["PrimaryCurrencyCode"] = that.selectedCalculation.currency;
            requestBody["CurrencyRate"] = 1;
            requestBody["Rate"] = 1;
            requestBody["CustomerTaxDisplayTypeId"] = "ExcludingTax";
            requestBody["VatNumber"] = null;
            requestBody["VatNumberStatusId"] = 0;
            requestBody["CustomerEmail"] = typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Email`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Email`] : thatCustomer.user.email;
            requestBody["FirstName"] = typeof shortInsurerTypeValuePairs[`${insurerPrefix}.FirstName`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.FirstName`] : thatCustomer.user.firstName;
            requestBody["LastName"] = typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Surname`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Surname`] : thatCustomer.user.lastName;
            requestBody["OrderTotal"] = that.selectedCalculation.price;
            requestBody["PaidAmount"] = 0;
            requestBody["CustomerLanguageId"] = that.gnLanguage.Id;
            requestBody["OrderTags"] = [];
            requestBody["CheckoutAttributes"] = [];
            requestBody["BillingAddress"] = {
                "FirstName": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.FirstName`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.FirstName`] : thatCustomer.user.firstName,
                "LastName": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Surname`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Surname`] : thatCustomer.user.lastName,
                "Email": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Email`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Email`] : thatCustomer.user.email,
                        // (!!thatCustomer && thatCustomer.email.length > 0 ? thatCustomer.email : 
                        //     (typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Pesel`] !== 'undefined' ? (thatCustomer.pesel ? `top_${thatCustomer.pesel}@a-soft.pl` : `top_${shortInsurerTypeValuePairs[`${insurerPrefix}.Pesel`]}@a-soft.pl`) : "")),
                "Company": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.CompanyName`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.CompanyName`] : thatCustomer.company,
                "VatNumber": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.Nip`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.Nip`] : thatCustomer.nip,
                "CountryId": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.Country.Name`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.Country.Name`] : thatCustomer.countryId,
                "StateProvinceId": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.County`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.County`] : thatCustomer.stateProvinceId,
                "City": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.City`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.City`] : thatCustomer.city,
                "Address1": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.Street`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.Street`] : thatCustomer.streetAddress,
                "Address2": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.HouseNumber`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.HouseNumber`] : thatCustomer.streetAddress2,
                "ZipPostalCode": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.PostCode`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.HomeAddress.PostCode`] : thatCustomer.zipPostalCode,
                "PhoneNumber": typeof shortInsurerTypeValuePairs[`${insurerPrefix}.MobilePhone`] !== 'undefined' ? shortInsurerTypeValuePairs[`${insurerPrefix}.MobilePhone`] : thatCustomer.phone,
                "CustomAttributes": null,
                "Id": !!thatCustomer && !!thatCustomer.Id ? thatCustomer.Id : null,
            };
            requestBody["OrderItems"] = [{
                "ProductId": that.product?.Id,
                "VendorId": that.product?.VendorId,
                "SeId": null,
                "Quantity": 1,
                "Status": "Open",
                "TaxRate": 0,
                "UnitPriceWithoutDiscInclTax": 0,
                "UnitPriceWithoutDiscExclTax": 0,
                "UnitPriceInclTax": 0,
                "UnitPriceExclTax": 0,
                "PriceInclTax": 0,
                "PriceExclTax": that.selectedCalculation.price,
                "CreatedOnUtc": that.product?.CreatedOnUtc,
                "AttributeDescription": attributeDescription,
                "Attributes": attributes,
                "Id": that.product?.Id
            }];
            requestBody["OrderTaxes"] = [{
                "Percent": 0,
                "Amount": 0,
                "Id": ""
            }];

            if(requestType === 'updateOrder') {
                requestBody["id"] = that.props.policyCalculation?.payloadId;
                requestBody["Id"] = that.props.policyCalculation?.payloadId;
                requestBody["OrderNumber"] = that.editedOrder.OrderNumber;
            }

            break;
        // case "addAddress":
        // case "updateAddress":
        //     requestBody["firstName"] = typeof that.customerTypeValuePairs['firstName'] !== 'undefined' ? that.customerTypeValuePairs['firstName'] : (customerName === null ? "" : customerName.FirstName);
        //     requestBody["lastName"] = typeof that.customerTypeValuePairs['lastName'] !== 'undefined' ? that.customerTypeValuePairs['lastName'] : (customerName === null ? "" : customerName.LastName);
        //     requestBody["email"] = getCustomerAddressData(thatCustomer, "Email");
        //     requestBody["company"] = getCustomerAddressData(thatCustomer, "Company");
        //     requestBody["vatNumber"] = getCustomerAddressData(thatCustomer, "VatNumber");
        //     requestBody["countryId"] = typeof that.customerTypeValuePairs['countryId'] !== 'undefined' ? that.customerTypeValuePairs['countryId'] : getCustomerAddressData(thatCustomer, "CountryId");
        //     requestBody["stateProvinceId"] = getCustomerAddressData(thatCustomer, "StateProvinceId");
        //     requestBody["city"] = typeof that.customerTypeValuePairs['city'] !== 'undefined' ? that.customerTypeValuePairs['city'] : getCustomerAddressData(thatCustomer, "City");
        //     requestBody["address1"] = typeof that.customerTypeValuePairs['address1'] !== 'undefined' ? that.customerTypeValuePairs['address1'] : getCustomerAddressData(thatCustomer, "Address1");
        //     requestBody["address2"] = typeof that.customerTypeValuePairs['address2'] !== 'undefined' ? that.customerTypeValuePairs['address2'] : getCustomerAddressData(thatCustomer, "Address2");
        //     requestBody["zipPostalCode"] = typeof that.customerTypeValuePairs['zip'] !== 'undefined' ? that.customerTypeValuePairs['zip'] : getCustomerAddressData(thatCustomer, "ZipPostalCode");
        //     requestBody["phoneNumber"] = typeof that.customerTypeValuePairs['phone'] !== 'undefined' ? that.customerTypeValuePairs['phone'] : getCustomerAddressData(thatCustomer, "PhoneNumber");
        //     requestBody["faxNumber"] = typeof that.customerTypeValuePairs['faxNumber'] !== 'undefined' ? that.customerTypeValuePairs['faxNumber'] : getCustomerAddressData(thatCustomer, "FaxNumber");
        //     requestBody["customAttributes"] = "";
            
        //     if(requestType === "updateAddress") {
        //         requestBody["Id"] = getCustomerAddressData(thatCustomer, "Id", true);
        //     }

        //     break;
    }

    return requestBody;
}

export function getReducedDataObjects(dataObjFiltered: any, that: any, doNotPushInsurerSpecific?: boolean): any[] {
    let reducedDataObjFiltered: any[] = [];

    let currentProductMap: string = "";
    if(that.product && that.product.UserFields) {
        let filteredProductMap: any = filterBySome(that.product.UserFields, 'Key', 'map');
        if(!!filteredProductMap) {
            currentProductMap = filteredProductMap.Value;
        }
    }

    const insuranceCompanyArray: string[] = parseEnumToStringArray(InsuranceCompanyNewOffer, true);

    dataObjFiltered.forEach((dataObj: any) => {
        let doNotPush: boolean = false;

        // if(!!that.selectedCalculation && !!that.inputsIdUserFieldsPairs[dataObj.productAttributeId]) {
        if(!!that.inputsIdUserFieldsPairs[dataObj.productAttributeId]) {
            that.inputsIdUserFieldsPairs[dataObj.productAttributeId].some((userField: any) => {
                if(doNotPushInsurerSpecific === true && userField.Key === 'key') {
                    const splittedKey: string[] = userField.Value.split('.');

                    splittedKey.some((keyPart: string) => {
                        if(insuranceCompanyArray.includes(keyPart.toLowerCase())) {
                            doNotPush = true;
                            return true;
                        }
                        return false;
                    });

                    if(doNotPush === true) {
                        return true;
                    }
                }

                if(!!that.selectedCalculation && userField.Key === 'allowTuningForInsurer') {
                    let splittedValue = userField.Value.split(";;");
                    if(!splittedValue.includes(that.selectedCalculation.insurerName)) {
                        doNotPush = true;
                    }
                    return true;
                }

                if(currentProductMap !== 'map_vehicle' && userField.Key === 'core_path' &&
                    (userField.Value.substring(0, 7) === 'Vehicle' || userField.Value.substring(0, 5) === 'Owner')
                ) {
                    doNotPush = true;
                    return true;
                }

                return false;
            });
        }

        if(doNotPush === false) {
            reducedDataObjFiltered.push(dataObj);
        }
    });

    return reducedDataObjFiltered;
}

export function getDataFromOrderToEdit(orderAttributes: any, productId: string, keysToIdMapper: KeysToIdMapper = defaultKeysToIdMapper): any {
    let parsedApiCall: any = {};

    if(orderAttributes && orderAttributes.length > 0) {
        let dataFound = 0;
        orderAttributes.some((attribute: any) => {
            if(attribute.Key === mapAttributeNameToId("dataObjFiltered", productId, keysToIdMapper)) {
                parsedApiCall[attribute.Key] = JSON.parse(attribute.Value);
                dataFound++;
            } else if(attribute.Key === mapAttributeNameToId("apiCall", productId, keysToIdMapper)) {
                parsedApiCall[attribute.Key] = JSON.parse(attribute.Value);
                dataFound++;
            } else if(attribute.Key === 'calculationId' || attribute.Key === mapAttributeNameToId("insurerId", productId, keysToIdMapper) || attribute.Key === mapAttributeNameToId("insurer", productId, keysToIdMapper)) {
                parsedApiCall[attribute.Key] = attribute.Value;
                dataFound++;
            }

            if(dataFound === 5) {
                return true;
            }

            return false;
        })
    }
    
    return parsedApiCall;
}

export function getDataFromCalculationToEdit(calculationPayload: string): any {
    let parsedApiCall: any = {};

    if(isJsonString(calculationPayload)) {
        parsedApiCall = JSON.parse(calculationPayload);
    }

    return parsedApiCall;
}

export function isCalculationIdSet(calculationId: any): boolean {
    if(typeof calculationId === 'number' && calculationId > 0) {
        return true;
    } else if(typeof calculationId === 'string' && calculationId.length > 0) {
        return true;
    } else {
        return false;
    }
}

export function makeId(length: number) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function renderElement(element: ContentViewModelProperty, show: boolean, iconData: any, that: any, error?: any): any {
    const toggleAsyncActionFlagDurationMs: number = 1000;

    if(!show) {
        element.type = '';
    }

    switch(element.type) {
        case Controls.Text:
            return <LabeledTextField key={element.additionalOptions.validationData && element.additionalOptions.validationData.mask ? `${element.id}` : `${element.id}-${Math.round(Math.random() * 100)}`} 
                    required={element.isRequired} label={L(element.label)} errorMessage={error && error[element.id] ? error[element.id] : ''}
                    rows={element.additionalOptions.rows} multiline={element.additionalOptions.rows > 1} 
                    defaultValue={typeof that.props.inputsTypeValuePairs[element.id] === 'number' ? that.props.inputsTypeValuePairs[element.id].toString() : that.props.inputsTypeValuePairs[element.id]} 
                    type={element.additionalOptions.textType === 'number' ? 'text' : element.additionalOptions.textType}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData} 
                    tooltipText={element.additionalOptions.tooltipText}
                    iconName={iconData && iconData.iconName ? iconData.iconName : null} 
                    iconOnClick={(iconIndex?: number) => { 
                        if(iconData && iconData.iconOnClick) {
                            if(!isJsonString(iconData.iconOnClick)) {
                                that[iconData.iconOnClick](iconData.iconDataToPass ? iconData.iconDataToPass : undefined);
                            } else {
                                let parsedIconOnClick: any = JSON.parse(iconData.iconOnClick);
                                if(parsedIconOnClick && Array.isArray(parsedIconOnClick) && typeof that[parsedIconOnClick[iconIndex ? iconIndex : 0]] === 'function') {
                                    that[parsedIconOnClick[iconIndex ? iconIndex : 0]](iconData.iconDataToPass ? iconData.iconDataToPass : undefined);
                                }
                            }
                        }
                    }}
                    onChange={(e: any, newValue: string | undefined) => {
                        if(typeof that.debouncedOnInputChange === 'function') {
                            that.debouncedOnInputChange(element.id, newValue ? newValue : (e.target && e.target.value ? e.target.value : (typeof e === 'string' ? e : '')), that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id], (element.additionalOptions && element.additionalOptions.additionalMethod ? element.additionalOptions.customPayload : undefined));
                        } else {
                            if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                                element.additionalOptions.additionalMethod(newValue ? newValue : (e.target && e.target.value ? e.target.value : (typeof e === 'string' ? e : '')), element.additionalOptions.customPayload);
                            }
                            
                            that.props.onInputChange(element.id, newValue ? newValue : (e.target && e.target.value ? e.target.value : (typeof e === 'string' ? e : '')), that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                        }
                    }} labelContainerCustomStyles={{minWidth:'350px'}} customLabelStyles={{width:'350px', marginRight:'30px'}}
                    customShimmerStyles={{maxWidth: '300px'}}
            />;
        case Controls.Date:
            return <DatePickerBase key={element.id} required={element.isRequired} label={L(element.label)} value={that.props.inputsTypeValuePairs[element.id]}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    tooltipText={element.additionalOptions.tooltipText} doNotFormatResult={true}
                    onChange={(e: any) => {
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(e, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, e, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} labelContainerCustomStyles={{minWidth:'350px'}} customLabelStyles={{width:'350px', marginRight:'30px'}}
            />;
        case Controls.Time:
            return <TimePicker key={element.id} label={L(element.label)} value={that.props.inputsTypeValuePairs[element.id]} disabled={element.disabled} 
                    isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData} onChange={(e) => {
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(e, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, e, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} />;
        case Controls.Picker:
            return <DropdownBase key={element.id} required={element.isRequired} label={L(element.label)} options={element.options.dropdown} value={that.props.inputsTypeValuePairs[element.id]}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    tooltipText={element.additionalOptions.tooltipText}
                    iconName={iconData && iconData.iconName ? iconData.iconName : null}
                    iconOnClick={(iconIndex?: number) => { 
                        if(iconData && iconData.iconOnClick) {
                            if(!isJsonString(iconData.iconOnClick)) {
                                that[iconData.iconOnClick](iconData.iconDataToPass ? iconData.iconDataToPass : undefined);
                            } else {
                                let parsedIconOnClick: any = JSON.parse(iconData.iconOnClick);
                                if(parsedIconOnClick && Array.isArray(parsedIconOnClick) && typeof that[parsedIconOnClick[iconIndex ? iconIndex : 0]] === 'function') {
                                    that[parsedIconOnClick[iconIndex ? iconIndex : 0]](iconData.iconDataToPass ? iconData.iconDataToPass : undefined);
                                }
                            }
                        }
                    }}
                    onChange={(e: any) => {
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(e, element.additionalOptions.customPayload);
                        }
                        
                        that.props.onInputChange(element.id, e, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} labelContainerCustomStyles={{minWidth:'350px'}} customLabelStyles={{width:'350px', marginRight:'30px'}}
            />;
        case Controls.MultiPicker:
            return <MultiDropdownBase key={element.id} required={element.isRequired} label={L(element.label)} options={element.options.dropdown} value={that.props.inputsTypeValuePairs[element.id]}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    onChange={(e: any) => {
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(e, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, e, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} labelContainerCustomStyles={{minWidth:'350px'}} customLabelStyles={{width:'350px', marginRight:'30px'}}
            />;
        case Controls.CheckBox:
            return <CheckBoxBase key={element.id} label={L(element.label)} value={that.props.inputsTypeValuePairs[element.id]}
                    disabled={element.disabled} validationData={element.additionalOptions.validationData}
                    onChange={(e: any) => {
                        if(typeof that.props.toggleAsyncActionFlag === 'function') {
                            that.props.toggleAsyncActionFlag(true, true);
                            setTimeout(function() {
                                that.props.toggleAsyncActionFlag(false, true);
                            }, toggleAsyncActionFlagDurationMs);
                        }
                        
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(e, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, e, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} containerCustomStyles={{minWidth:'350px', marginRight:'30px'}}
            />;
        case Controls.CheckBoxOptions:
            return <CheckBoxOptions key={element.id} label={L(element.label)} options={element.options.dropdown}
                    disabled={element.disabled} validationData={element.additionalOptions.validationData}
                    onChange={(e: any) => {
                        if(typeof that.props.toggleAsyncActionFlag === 'function') {
                            that.props.toggleAsyncActionFlag(true, true);
                            setTimeout(function() {
                                that.props.toggleAsyncActionFlag(false, true);
                            }, toggleAsyncActionFlagDurationMs);
                        }

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(e, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, e, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} customLabelStyles={{width:'350px', marginRight:'30px', marginTop: '20px'}}
            />;
        case Controls.ChoiceGroup:
            return <ChoiceGroupBase key={element.id} label={L(element.label)} value={that.props.inputsTypeValuePairs[element.id]}
                    disabled={element.disabled} options={element.options.choicegroup}
                    onChange={(e) => {
                        if(typeof that.props.toggleAsyncActionFlag === 'function') {
                            that.props.toggleAsyncActionFlag(true, true);
                            setTimeout(function() {
                                that.props.toggleAsyncActionFlag(false, true);
                            }, toggleAsyncActionFlagDurationMs);
                        }

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod((e && e.key ? e.key : e), element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, (e && e.key ? e.key : e), that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} />;
        case Controls.TableInputs:
            const rowsCount: number = element.options.tableInputs.tableRowsCountFromInputId && that.props.inputsTypeValuePairs[element.options.tableInputs.tableRowsCountFromInputId] 
                                        ? parseInt(that.props.inputsTypeValuePairs[element.options.tableInputs.tableRowsCountFromInputId]) : 
                                            (that.props.inputsTypeValuePairs[element.id] ? 
                                                (typeof that.props.inputsTypeValuePairs[element.id] === 'object' ? Object.keys(that.props.inputsTypeValuePairs[element.id]).length : 
                                                    (typeof that.props.inputsTypeValuePairs[element.id] === 'string' && isJsonString(that.props.inputsTypeValuePairs[element.id])) ?
                                                        Object.keys(JSON.parse(that.props.inputsTypeValuePairs[element.id])).length : -1) : -1);

            return <TableInputsBase key={element.id} label={L(element.label)} options={element.options.tableInputs} inputsForTable={that.templateInputsForTable[element.id]}
                    rowsCount={rowsCount}
                    value={that.props.inputsTypeValuePairs[element.id]} isDataLoaded={element.additionalOptions.isDataLoaded}
                    iconName={iconData && iconData.iconName ? iconData.iconName : null}
                    iconOnClick={ (iconIndex?: number) => { 
                        if(iconData && iconData.iconOnClick) {
                            if(!isJsonString(iconData.iconOnClick)) {
                                that[iconData.iconOnClick](iconData.iconDataToPass ? iconData.iconDataToPass : undefined);
                            } else {
                                let parsedIconOnClick: any = JSON.parse(iconData.iconOnClick);
                                if(parsedIconOnClick && Array.isArray(parsedIconOnClick) && typeof that[parsedIconOnClick[iconIndex ? iconIndex : 0]] === 'function') {
                                    that[parsedIconOnClick[iconIndex ? iconIndex : 0]](iconData.iconDataToPass ? iconData.iconDataToPass : undefined);
                                }
                            }
                        }
                    }}
                    onChange={(jsonValue) => {
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(jsonValue, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, jsonValue, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }}
            />;
        case Controls.CountriesComboBox:
            return <CountriesComboBox key={element.id} label={L(element.label)} required={element.isRequired} value={that.props.inputsTypeValuePairs[element.id]}
                    validationData={element.additionalOptions.validationData}
                    onInputChange={(id: string | number | undefined, value: any) => { 
                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(id, element.additionalOptions.customPayload);
                        }

                        that.props.onInputChange(element.id, id, that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                    }} labelContainerCustomStyles={{minWidth: '350px'}} customLabelStyles={{width: '350px', marginRight: '30px'}}
            />;
        case Controls.CountrySearchList:
            return <div className={fluentTableClassNames.contentContainer}>
                <CountryFluentListBase
                    searchText={undefined}
                    items={that.props.travelCountryStore?.dataSet && that.props.travelCountryStore?.dataSet.items ? that.props.travelCountryStore?.dataSet.items : []}
                    store={that.props.travelCountryStore!}
                    scrollablePanelMarginTop={that.props.inputsTypeValuePairs && that.props.inputsTypeValuePairs[element.id] && isJsonString(that.props.inputsTypeValuePairs[element.id]) ?
                        54 + (Math.floor(JSON.parse(that.props.inputsTypeValuePairs[element.id]).length / 6) * 30) : 54}
                    customData={{ 
                        forceFilterSearch: true,
                        selectedItems: that.props.inputsTypeValuePairs && that.props.inputsTypeValuePairs[element.id] && isJsonString(that.props.inputsTypeValuePairs[element.id]) ? 
                                        JSON.parse(that.props.inputsTypeValuePairs[element.id]) : (Array.isArray(that.props.inputsTypeValuePairs[element.id]) ? that.props.inputsTypeValuePairs[element.id] : undefined),
                        required: element.isRequired,
                        disableGetAllOnMount: element.additionalOptions.validationData.disableGetAllOnMount ? element.additionalOptions.validationData.disableGetAllOnMount : false,
                    }}
                    // customOnSearchTextChanged={(text: string) => { that.selectCountrySearchText = text; that.forceUpdate(); }}
                    customSelection={that._countryListSelection}
                    customOnSelectionChanged={(payload: any) => {
                        if(payload && payload.action && payload.action === 'deleteCountry') {
                            const cloneItems: any[] = [];
                            if(that.props.inputsTypeValuePairs[element.id] && (Array.isArray(that.props.inputsTypeValuePairs[element.id]) || isJsonString(that.props.inputsTypeValuePairs[element.id]))) {
                                const objToIterate: any[] = isJsonString(that.props.inputsTypeValuePairs[element.id]) ? JSON.parse(that.props.inputsTypeValuePairs[element.id]) : that.props.inputsTypeValuePairs[element.id];
                                objToIterate.forEach((element: any) => {
                                    if(element.id !== payload.id) {
                                        cloneItems.push(element);
                                    }
                                });
                            }
                            that.props.onInputChange(element.id, JSON.stringify(cloneItems), that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                        }
                    }}
                />
            </div>
        case Controls.SportSearchList:
            return <div className={fluentTableClassNames.contentContainer}>
                <SportFluentListBase
                    searchText={undefined}
                    items={that.props.sportDisciplineStore?.dataSet && that.props.sportDisciplineStore?.dataSet.items ? that.props.sportDisciplineStore?.dataSet.items : []}
                    store={that.props.sportDisciplineStore!}
                    scrollablePanelMarginTop={that.props.inputsTypeValuePairs && that.props.inputsTypeValuePairs[element.id] && isJsonString(that.props.inputsTypeValuePairs[element.id]) ?
                        70 + (Math.floor(JSON.parse(that.props.inputsTypeValuePairs[element.id]).length / 2) * 30) : 54}
                    customData={{ 
                        forceFilterSearch: true, 
                        selectedItems: that.props.inputsTypeValuePairs && that.props.inputsTypeValuePairs[element.id] && isJsonString(that.props.inputsTypeValuePairs[element.id]) ?
                                        JSON.parse(that.props.inputsTypeValuePairs[element.id]) : undefined,
                        required: element.isRequired,
                    }}
                    // customOnSearchTextChanged={(text: string) => { that.selectSportSearchText = text; that.forceUpdate(); }}
                    customSelection={that._sportListSelection}
                    customOnSelectionChanged={(payload: any) => {
                        if(payload && payload.action && payload.action === 'deleteSport') {
                            const cloneItems: any[] = [];
                            if(that.props.inputsTypeValuePairs[element.id] && (Array.isArray(that.props.inputsTypeValuePairs[element.id]) || isJsonString(that.props.inputsTypeValuePairs[element.id]))) {
                                const objToIterate: any[] = isJsonString(that.props.inputsTypeValuePairs[element.id]) ? JSON.parse(that.props.inputsTypeValuePairs[element.id]) : that.props.inputsTypeValuePairs[element.id];
                                objToIterate.forEach((element: any) => {
                                    if(element.id !== payload.id) {
                                        cloneItems.push(element);
                                    }
                                });
                            }
                            that.props.onInputChange(element.id, JSON.stringify(cloneItems), that.tempInputIdUserFieldPairs && that.tempInputIdUserFieldPairs[element.id]);
                        }
                    }}
                />
            </div>
        default:
            return;
    }
}

function createInputDataObjectForApiRequest(key: string, inputsIdUserFieldsPairs: any, attrValue: any, selectedUserFields: string[] = ['core_path', 'core_type'], attrValueSource?: any, stringifySource?: boolean): any {
    if(typeof attrValue === 'object') {
        return undefined;
    }
    
    const tempObject = getSelectedUserFields(inputsIdUserFieldsPairs[key], selectedUserFields);

    tempObject['value'] = typeof attrValue !== 'undefined' && attrValue !== null && attrValue.length > 0 ? attrValue : (!!attrValueSource ? attrValueSource.toString() : '');
    tempObject['productAttributeId'] = key;
    if(!!attrValueSource) {
        if(stringifySource && stringifySource === true) {
            tempObject['valueId'] = typeof attrValueSource !== 'string' ? JSON.stringify(attrValueSource) : attrValueSource;
        } else {
            tempObject['valueId'] = attrValueSource.toString();
        }
    }
    return tempObject;
}

export function getSelectedUserFields(userFields: any, keysToGet: string[]): any {
    let result: any = {};
    let userFieldsFound: number = 0;

    if(userFields && keysToGet && keysToGet.length > 0) {
        userFields.some((userField: any) => {
            if(keysToGet.includes(userField.Key)) {
                result[userField.Key] = userField.Value;
                userFieldsFound++;
            }
    
            if(userFieldsFound === keysToGet.length) {
                return true;
            }
            return false;
        });
    }

    return result;
}

export function getAdjustInputsValues(allowForInsurerValue: string, gnLanguage: any, insuranceCompanyStore?: any): any {
    let noBracketsValue: string = allowForInsurerValue.substring(1, allowForInsurerValue.length - 1);
    let inputsIdsString: string[] = noBracketsValue.split(':"');
    let inputsIdValuesPairs: any = {};

    let lastFoundId: string = "";
    inputsIdsString.forEach((element: string) => {
        element.split(';;').forEach((elString: string) => {
            if(elString.length > 0) {
                if(elString.substring(0,3) !== 'Key') {
                    if(insuranceCompanyStore && insuranceCompanyStore.dataSet && insuranceCompanyStore.dataSet.totalCount > 0) {
                        const convertedInsurerNameToId = mapGnInsurerNameToId(elString, insuranceCompanyStore.dataSet.items); 
                        inputsIdValuesPairs[convertedInsurerNameToId] = [];
                        lastFoundId = convertedInsurerNameToId;
                    } else {
                        inputsIdValuesPairs[elString] = [];
                        lastFoundId = elString;
                    }
                } else {
                    if(elString.slice(-1) === '"') {
                        elString = elString.slice(0, -1);
                    }

                    let key: string = elString.substring(4).split(';')[0];
                    let tempText: any = elString.split('Text:')[1];
                    tempText = tempText.substring(1, tempText.length - 1).split(';');
                    let text: string = "";

                    tempText.forEach((textWithLang: string) => {
                        const lang = textWithLang.split('=')[0];
                        const translatedText = textWithLang.split('=')[1];

                        if(lang.toLowerCase() === gnLanguage.UniqueSeoCode.toLowerCase()) {
                            text = translatedText;
                        }
                    });

                    inputsIdValuesPairs[lastFoundId].push({key, text});
                }
            }
        });
    });

    return inputsIdValuesPairs;
}

export function mapGnInsurerNameToId(insurerName: string, insuranceCompanyStoreItems: InsuranceCompanyDto[]): string {
    let valueToReturn: string = insurerName;

    if(insurerName.length > 0 && insuranceCompanyStoreItems.length > 0) {
        insuranceCompanyStoreItems.some((insuranceCompany: InsuranceCompanyDto) => {
            if(insuranceCompany.Name === insurerName) {
                valueToReturn = insuranceCompany.Id;
                return true;
            }
            return false;
        });
    }

    return valueToReturn;
}

export function getAttributeNameAndValueShort(ProductAttributeMappings: any, gnLanguage: any, keyPairValue: any): any { // {"parentKey": key}
    let returnAttrName = "";
    let returnAttrValue = "";
    let returnAttrTranslatedValue = "";

    ProductAttributeMappings!.some((ProductAttributeMapping: any, i: number) => {
        if(ProductAttributeMapping.ProductAttribute) {
            if(ProductAttributeMapping.ProductAttribute.Locales) {
                returnAttrName = getLocaleName(ProductAttributeMapping.ProductAttribute.Locales, gnLanguage);
            } else {
                returnAttrName = ProductAttributeMapping.ProductAttribute.Name;
            }
        }

        if(ProductAttributeMapping.ProductAttributeValues && ProductAttributeMapping.ProductAttributeValues.length > 0) {
            ProductAttributeMapping.ProductAttributeValues.some((attrValue: any) => {
                
                if(attrValue.Id === keyPairValue) {
                    if(returnAttrTranslatedValue.length === 0) {
                        returnAttrTranslatedValue = attrValue.Locales && attrValue.Locales.length > 0 ? getLocaleName(attrValue.Locales, gnLanguage) : attrValue.Name;
                    }
                    if(returnAttrValue.length === 0) {
                        returnAttrValue = attrValue.Name;
                    }
                    return true;
                }
                return false;
            });
        }

        return false;
    });

    return {"name": returnAttrName, "value": returnAttrValue, "translatedValue": returnAttrTranslatedValue};
}

export function conditionalAttribute(attr: any, inputsTypeValuePairs: any, inputsIdUserFieldsPairs: any, productAttributeMappings: any, productAttributes: any, gnLanguage: any): boolean {
    let result: any = {
        show: true,
        disabled: false,
        newValue: null,
        forceOmitCheckIfInputsChagnedManually: false,
    };
    let attrJsonPropsUserField: any;

    if(inputsIdUserFieldsPairs && inputsIdUserFieldsPairs[attr.Id]) {
        attrJsonPropsUserField = filterBySome(inputsIdUserFieldsPairs[attr.Id], "Key", "attr_json_props");
    }

    if(attrJsonPropsUserField && !!attrJsonPropsUserField.Value) {
        result = getConditionalResultFromAttrJsonProps(attrJsonPropsUserField, inputsTypeValuePairs, productAttributeMappings, productAttributes, gnLanguage);
    } else if(attr.ConditionAttribute && attr.ConditionAttribute.length > 0) {
        attr.ConditionAttribute.forEach((condition: any) => {
            if(typeof inputsTypeValuePairs[condition.Key] === 'object' && inputsTypeValuePairs[condition.Key][condition.Value] === true) {
                result.show = true;
            } else if(inputsTypeValuePairs[condition.Key] === condition.Value) {
                result.show = true;
            } else {
                result.show = false;
            }
        });
    }

    if(result.newValue === null && (attrJsonPropsUserField && attrJsonPropsUserField.Value) && (!inputsTypeValuePairs[attr.Id] || inputsTypeValuePairs[attr.Id] === '')) {
        const jsonProps = isJsonString(attrJsonPropsUserField.Value) ? JSON.parse(attrJsonPropsUserField.Value) : attrJsonPropsUserField;

        if(jsonProps.Default && jsonProps.Default.DefaultValue && !!jsonProps.Default.DefaultValue) {
            result.newValue = jsonProps.Default.DefaultValue;
        }
    }

    return result;
}

export function getConditionalResultFromAttrJsonProps(attrJsonPropsUserField: any, inputsTypeValuePairs: any, productAttributeMappings: any, productAttributes: any, gnLanguage: any, jsonAlreadyParsed?: boolean): boolean {
    let result: any = {
        show: true,
        disabled: false,
        newValue: null,
        forceOmitCheckIfInputsChagnedManually: false,
    };
    const jsonProps = jsonAlreadyParsed === true ? attrJsonPropsUserField : JSON.parse(attrJsonPropsUserField.Value);
    
    if(!!jsonProps.Default && !!jsonProps.Default.IsHidden && jsonProps.Default.IsHidden === true) {
        result.show = false;
    }
    if(!!jsonProps.Default && !!jsonProps.Default.IsDisabled && jsonProps.Default.IsDisabled === true) {
        result.disabled = true;
    }
    if(!!jsonProps.Default && !!jsonProps.Default.ForceOmitCheckIfInputsChagnedManually && jsonProps.Default.ForceOmitCheckIfInputsChagnedManually === true) {
        result.forceOmitCheckIfInputsChagnedManually = true;
    }
    
    if(!!jsonProps.Conditions && jsonProps.Conditions.length > 0) {
        let tempProductAttributeMappings: any = [];
        
        productAttributeMappings.forEach((productAttributeMapping: any) => {
            const productAttribute = filterBySome(productAttributes, "Id", productAttributeMapping.ProductAttributeId);
            tempProductAttributeMappings.push({...productAttributeMapping, ProductAttribute: productAttribute});
        });

        if(jsonProps.Default && !!jsonProps.Default.IsHidden && jsonProps.Default.IsHidden === true) {
            result.show = false;
        }
        if(jsonProps.Default && !!jsonProps.Default.IsDisabled && jsonProps.Default.IsDisabled === true) {
            result.disabled = true;
        }
        if(jsonProps.Default && !!jsonProps.Default.ForceOmitCheckIfInputsChagnedManually && jsonProps.Default.ForceOmitCheckIfInputsChagnedManually === true) {
            result.forceOmitCheckIfInputsChagnedManually = true;
        }

        let highestPriorityConditionIndex: number = -1;

        jsonProps.Conditions.forEach((condition: any, conditionIndex: number) => {
            let conditionFulfiled: boolean = true;

            if(!!condition.ConditionDetails) {
                condition.ConditionDetails.some((ConditionDetail: any) => {
                    let tempAttrValue: string = getInputValueByKey(ConditionDetail.Key, inputsTypeValuePairs, tempProductAttributeMappings, gnLanguage);

                    if(ConditionDetail.IsValueADate) {
                        const keysToParse: string[] = ['pesel', 'PESEL', 'ChildPESEL'];
                        let attrDateValueToCompare: Moment = moment(tempAttrValue);
                        let sourceDateForComparsion: Moment | undefined = undefined;
                        let otherDateForComparsion: Moment | undefined = undefined;

                        if(!!ConditionDetail.KeyOfInputWithDate) {
                            let dateForComparsion = getInputValueByKey(ConditionDetail.KeyOfInputWithDate, inputsTypeValuePairs, tempProductAttributeMappings, gnLanguage);
                            if(!!dateForComparsion && moment(dateForComparsion).isValid()) {
                                sourceDateForComparsion = moment(dateForComparsion);
                            }    
                        }

                        if((!!ConditionDetail.DateComparsionType && ConditionDetail.DateComparsionType === "betweenValueAndDate") && !!ConditionDetail.OtherDateForComparsion) {
                            if(ConditionDetail.OtherDateForComparsion.split('_')[0] === 'KEY' && !!ConditionDetail.KeyOfInputWithOtherDate) {
                                let secondDateForComparsion = getInputValueByKey(ConditionDetail.KeyOfInputWithOtherDate, inputsTypeValuePairs, tempProductAttributeMappings, gnLanguage);
                                
                                if(!!secondDateForComparsion && moment(secondDateForComparsion).isValid()) {
                                    otherDateForComparsion = moment(secondDateForComparsion);
                                }

                                const splittedOtherDateForComparsion: string[] = ConditionDetail.OtherDateForComparsion.split('_');

                                if(splittedOtherDateForComparsion[0] === 'TODAY') {
                                    otherDateForComparsion = moment();
                                }

                                if(otherDateForComparsion && moment.isMoment(otherDateForComparsion) && 
                                    (splittedOtherDateForComparsion[1] === 'MINUS' || splittedOtherDateForComparsion[1] === 'PLUS') && 
                                    !isNaN(parseInt(splittedOtherDateForComparsion[2])) && !!splittedOtherDateForComparsion[3]
                                ) {
                                    const amount = parseInt(splittedOtherDateForComparsion[2]);
                                    const unit = splittedOtherDateForComparsion[3].toLowerCase();
                                    
                                    if(splittedOtherDateForComparsion[1] === 'MINUS') {
                                        otherDateForComparsion.subtract(amount as moment.DurationInputArg1, unit as moment.DurationInputArg2);
                                    } else {
                                        otherDateForComparsion.add(amount as moment.DurationInputArg1, unit as moment.DurationInputArg2);
                                    }
                                }
                            } else if(moment(ConditionDetail.OtherDateForComparsion).isValid()) {
                                otherDateForComparsion = moment(ConditionDetail.OtherDateForComparsion);
                            }
                        }

                        // check if key is PESEL input
                        const splittedKey: string[] = ConditionDetail.Key.split('.');
                        if(keysToParse.includes(splittedKey[splittedKey.length - 1]) && !!tempAttrValue) {
                            const decodedPesel: any = peselDecode(tempAttrValue);

                            if(moment(decodedPesel.date).isValid()) {
                                attrDateValueToCompare = moment(decodedPesel.date);
                            }
                        }
                        
                        if(!!ConditionDetail.Value) {
                            if(moment(ConditionDetail.Value).isValid()) {
                                sourceDateForComparsion = moment(ConditionDetail.Value);
                            } else {
                                const splittedValue: string[] = ConditionDetail.Value.split('_');

                                if(splittedValue[0] === 'TODAY') {
                                    sourceDateForComparsion = moment();
                                }

                                if(moment.isMoment(sourceDateForComparsion) && 
                                    (splittedValue[1] === 'MINUS' || splittedValue[1] === 'PLUS') && 
                                    !isNaN(parseInt(splittedValue[2])) && !!splittedValue[3]
                                ) {
                                    const amount = parseInt(splittedValue[2]);
                                    const unit = splittedValue[3].toLowerCase();
                                    
                                    if(splittedValue[1] === 'MINUS') {
                                        sourceDateForComparsion.subtract(amount as moment.DurationInputArg1, unit as moment.DurationInputArg2);
                                    } else {
                                        sourceDateForComparsion.add(amount as moment.DurationInputArg1, unit as moment.DurationInputArg2);
                                    }
                                }
                            }
                        }

                        if(!!ConditionDetail.DateComparsionType && moment.isMoment(attrDateValueToCompare) && moment.isMoment(sourceDateForComparsion)) {
                            if(ConditionDetail.DateComparsionType === "betweenValueAndDate" && moment.isMoment(otherDateForComparsion)) {
                                const dateA = sourceDateForComparsion.isSameOrBefore(otherDateForComparsion) ? sourceDateForComparsion : otherDateForComparsion;
                                const dateB = sourceDateForComparsion.isSameOrBefore(otherDateForComparsion) ? otherDateForComparsion : sourceDateForComparsion;

                                if(attrDateValueToCompare.isBetween(dateA, dateB)) {
                                    return false;
                                }
                            } else if(ConditionDetail.DateComparsionType === "beforeValue" && attrDateValueToCompare.isBefore(sourceDateForComparsion)) {
                                return false;
                            } else if(ConditionDetail.DateComparsionType === "afterValue" && attrDateValueToCompare.isAfter(sourceDateForComparsion)) {
                                return false;
                            }

                            conditionFulfiled = false;
                            return true;
                        }

                        return false;
                    }
                    
                    if(typeof ConditionDetail.Value === 'undefined' && !!tempAttrValue && (ConditionDetail.HasAnyValue === true || ConditionDetail.HasAnyValue === "true")) {
                        return true;
                    } else if(typeof ConditionDetail.Value === 'undefined') {
                        conditionFulfiled = false;
                        return false;
                    }

                    const re = new RegExp('\\b' + ConditionDetail.Value.toLowerCase() + '\\b','g');
                    const matches = tempAttrValue.toLocaleLowerCase().match(re);
                    
                    if(!!ConditionDetail && (ConditionDetail.IsValueExactlyTheSame === true || ConditionDetail.IsValueExactlyTheSame === "true")) {
                        if(tempAttrValue.toLowerCase() !== ConditionDetail.Value.toLowerCase()) {
                            conditionFulfiled = false;
                            return true;
                        }
                    } else if(!!ConditionDetail && (matches === null || matches?.length === 0)) {
                        if(!!tempAttrValue && (ConditionDetail.HasAnyValue === true || ConditionDetail.HasAnyValue === "true")) {
                            return false;
                        }

                        conditionFulfiled = false;
                        return true;
                    }
                    return false;
                });

                if(highestPriorityConditionIndex >= 0 && conditionFulfiled && condition.Priority > jsonProps.Conditions[highestPriorityConditionIndex].Priority) {
                    highestPriorityConditionIndex = conditionIndex;
                } else if(highestPriorityConditionIndex < 0 && conditionFulfiled) {
                    highestPriorityConditionIndex = conditionIndex;
                }
            }
        });

        if(highestPriorityConditionIndex >= 0) {
            if(!!jsonProps.Conditions[highestPriorityConditionIndex].IsHidden && jsonProps.Conditions[highestPriorityConditionIndex].IsHidden === true) {
                result.show = false;
            } else if(!!jsonProps.Conditions[highestPriorityConditionIndex].IsHidden && jsonProps.Conditions[highestPriorityConditionIndex].IsHidden === false) {
                result.show = true;
            } else if(!jsonProps.Conditions[highestPriorityConditionIndex].IsHidden) {
                result.show = true;
            }

            if(!!jsonProps.Conditions[highestPriorityConditionIndex].IsDisabled && jsonProps.Conditions[highestPriorityConditionIndex].IsDisabled === true) {
                result.disabled = true;
            } else if(!!jsonProps.Conditions[highestPriorityConditionIndex].IsDisabled && jsonProps.Conditions[highestPriorityConditionIndex].IsDisabled === false) {
                result.disabled = false;
            } else if(!jsonProps.Conditions[highestPriorityConditionIndex].IsDisabled) {
                result.disabled = false;
            }

            if(!!jsonProps.Conditions[highestPriorityConditionIndex].ForceOmitCheckIfInputsChagnedManually && jsonProps.Conditions[highestPriorityConditionIndex].ForceOmitCheckIfInputsChagnedManually === true) {
                result.forceOmitCheckIfInputsChagnedManually = true;
            } else if(!!jsonProps.Conditions[highestPriorityConditionIndex].ForceOmitCheckIfInputsChagnedManually && jsonProps.Conditions[highestPriorityConditionIndex].ForceOmitCheckIfInputsChagnedManually === false) {
                result.forceOmitCheckIfInputsChagnedManually = false;
            } else if(!jsonProps.Conditions[highestPriorityConditionIndex].ForceOmitCheckIfInputsChagnedManually) {
                result.forceOmitCheckIfInputsChagnedManually = false;
            }

            if(typeof jsonProps.Conditions[highestPriorityConditionIndex].DefaultValue !== 'undefined' && typeof jsonProps.Conditions[highestPriorityConditionIndex].DefaultValue === 'string') {
                result.newValue = jsonProps.Conditions[highestPriorityConditionIndex].DefaultValue;
            }
        }
    }

    return result;
}

export function getValueBasedOnClonedValue(valueToCopy: string, productAttributeMappingToChange: any, productAttributeMappingsToClone: any, attributeIdOfClonedMapping: string): string {
    let valueToReturn: string = "";

    let productAttributeMappingToClone: any = filterBySome(productAttributeMappingsToClone, "Id", attributeIdOfClonedMapping);
    if(!!productAttributeMappingToClone) {
        if(productAttributeMappingToClone.AttributeControlTypeId === "DropdownList") {
            let hasDataSource: any = filterBySome(productAttributeMappingToClone.ProductAttribute.UserFields, "Key", "dataSource");
            let productAttributeValue: any = filterBySome(productAttributeMappingToClone.ProductAttributeValues, "Id", valueToCopy);

            if(!hasDataSource && !!productAttributeValue && productAttributeValue.Name) {
                let productAttributeValueToGet: any = filterBySome(productAttributeMappingToChange.ProductAttributeValues, "Name", productAttributeValue.Name);
                if(!!productAttributeValueToGet) {
                    valueToReturn = productAttributeValueToGet.Id;
                }
            } else {
                valueToReturn = valueToCopy;
            }
        } else {
            valueToReturn = valueToCopy;
        }
    }
    
    return valueToReturn;
}

export function getInputValueByKey(key: string, inputsTypeValuePairs: any, tempProductAttributeMappings: any, gnLanguage: any) {
    let keyPairValue: any = inputsTypeValuePairs[mapAttributeKeyToId(tempProductAttributeMappings, key, true)];
    let attrValue: string = "";
    // let tempAttrTranslatedValue = "";

    if(typeof keyPairValue === 'string') {
        attrValue = keyPairValue;
    } else {
        let multipleValues: string[] = [];
        for(let jsonKey in keyPairValue) {
            if(keyPairValue.hasOwnProperty(jsonKey) && keyPairValue[jsonKey]) {
                multipleValues.push(getAttributeNameAndValueShort(tempProductAttributeMappings, gnLanguage, jsonKey).value);
            }
        }
        
        attrValue = multipleValues.join(', ');
    }

    let attrData = getAttributeNameAndValueShort(tempProductAttributeMappings, gnLanguage, keyPairValue);

    if(attrData.value.length > 0) {
        attrValue = attrData.value;
        // tempAttrTranslatedValue = attrData.translatedValue;
    } 
    // else {
    //     tempAttrTranslatedValue = attrValue;
    // }

    return attrValue;
}